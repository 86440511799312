interface Environment {
  production?: boolean;
  test?: boolean;
  version?: string;
  apiEntrypoint?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env: Environment = (window as any).__env || {};

export const isTest = env.test || false;
export const defaultDomain = 'indigo.world';
export const defaultApiEntrypoint = env.apiEntrypoint || `https://api.${defaultDomain}/`;
export const version = env.version || 'dev';

export let apiEntrypoint: string;

const setApiEntrypoint = (value: string): void => {
  apiEntrypoint = value;
  localStorage.setItem('apiEntrypoint', apiEntrypoint);
};

export const overrideApiEntrypoint = (value: string | null): void => {
  if (!value) {
    setApiEntrypoint(defaultApiEntrypoint);
    return;
  }

  if ('prod' === value) {
    value = `api.${defaultDomain}`;
  } else if ('preprod' === value) {
    value = `api.preprod.${defaultDomain}`;
  } else if (value?.match(/^api-\d+$/)) {
    value = `${value}.dev.${defaultDomain}`;
  } else if (value?.match(/^\d+$/)) {
    value = `api-${value}.dev.${defaultDomain}`;
  }

  if ('http' !== value.substr(0, 4)) {
    value = `https://${value}`;
  }

  setApiEntrypoint(value.replace(/\/$/, ''));
};

overrideApiEntrypoint(localStorage.getItem('apiEntrypoint') || null);
