export const defaultUser: UserType = {
  name: '',
  id: '',
  avatar: undefined,
};

export interface PublicSectorData {
  cityCodes?: [];
  cityKey?: string;
}

export interface ManagedData {
  publicSectorData?: PublicSectorData;
}

export interface UserType {
  name: string;
  id: string;
  avatar: ImageType | undefined;
  managedData?: ManagedData;
}

export interface ImageType {
  contentUrl: string;
}
