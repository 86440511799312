import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en/client.json';
import fr from './translations/fr/client.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    fallbackLng: 'fr',
    debug: false,

    whitelist: ['en', 'fr'], // Array of allowed languages
    load: 'languageOnly',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ['navigator'],
      excludeCacheFor: ['cimode', 'cookie', 'localStorage'], // languages to not persist (cookie, localStorage)
    },

    react: {
      useSuspense: true,
      wait: true,
    },
  });

export const localesLabels = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
];

export default i18n;
