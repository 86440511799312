import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import './i18n';
import './globals.scss';
import 'tabler-react/dist/Tabler.css';
import { fetchUser } from './utils/Axios';
import { defaultUser, UserType } from './Types/user';
// lazy components
const HomePage = React.lazy(() => import('./pages/HomePage'));

const urlParams = window.location.pathname.split('/');
const queryString = window.location.search;
const urlQueryParams = new URLSearchParams(queryString);

// TODO Validate urlParams length

const id = urlParams[1];
const cityKey = urlParams[2];

const shouldShowEstimates = (): boolean => {
  const param = urlQueryParams.get('showEstimates')?.toLowerCase();
  return param !== undefined && param !== 'false' && param !== 'off' && param !== '0';
};

const showEstimates = shouldShowEstimates();

const App: React.FunctionComponent = () => {
  const [user, setUser] = useState<UserType>(defaultUser);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchUser(id, cityKey)
      .then(function (user: UserType) {
        if (!user) {
          setIsError(true);
          return;
        }

        setUser(user);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  if (isError) {
    window.location.href = "https://indigo.world/fr/?error=Vous n'avez pas accès à cette page";
    return null;
  }

  return (
    <Suspense fallback={<div className="spinner-border" role="status" />}>
      {!isLoading && (
        <>
          <Header user={user} cityKey={cityKey} id={id} />
          <Router>
            <Switch>
              <Route path="/:id/:cityKey" component={() => <HomePage id={id} cityKey={cityKey} showEstimates={showEstimates} />} exact />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Router>
        </>
      )}
    </Suspense>
  );
};

export default App;
