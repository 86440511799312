import axios, { AxiosResponse } from 'axios';
import { apiEntrypoint } from '../environment';
import { UserType } from '../Types/user';
import { HydraCollectionResponse, Locality, StatsType } from '../Types/stats';

const API = axios.create({
  baseURL: apiEntrypoint,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/ld+json',
  },
});

export const fetchUser = (id: string, cityKey: string): Promise<UserType> => {
  return API.get(`/users/me`, {
    auth: {
      username: id,
      password: cityKey,
    },
  }).then((data: AxiosResponse<UserType>) => {
    if (data.status !== 200) {
      return Promise.reject('Invalid response code');
    }
    return data.data;
  });
};

export const fetchStats = (id: string, cityKey: string, dateFrom: string, dateTo: string, cityCode?: string): Promise<StatsType> => {
  const cityCodeParam = cityCode ? `&cityCodes[]=${cityCode}` : '';
  return API.get(`/users/me/public_sector/stats?from=${dateFrom}&to=${dateTo}${cityCodeParam}`, {
    auth: {
      username: id,
      password: cityKey,
    },
  }).then((data: AxiosResponse<StatsType>) => {
    if (data.status !== 200) {
      return Promise.reject('Invalid response code');
    }
    return data.data;
  });
};

export const fetchCities = (id: string, cityKey: string): Promise<Locality[]> => {
  return API.get(`/users/me/public_sector/localities`, {
    auth: {
      username: id,
      password: cityKey,
    },
  })
    .then((data: AxiosResponse<HydraCollectionResponse<Locality>>) => {
      if (data.status !== 200) {
        return Promise.reject('Invalid response code');
      }
      return data.data;
    })
    .then((collection: HydraCollectionResponse<Locality>) => {
      return collection['hydra:member'];
    });
};
