import React, { Component, ReactNode } from 'react';

import './Header.scss';
import { UserType } from '../Types/user';

interface StateProps {
  user: UserType;
  cityKey: string;
  id: string;
}

class Header extends Component<StateProps> {
  public render(): ReactNode {
    return (
      <div className="header py-4">
        <div className="container">
          <div className="d-flex margin-l-r">
            <a href={`/${this.props.id}/${this.props.cityKey}`}>
              <img src="./assets/logo-indigo-city.png" className="header-brand-img" alt="Indigo City" />
            </a>
            <div className="d-flex order-lg-2 ml-auto">
              <div className="block-user">
                <div className="nav-link">
                  <img className="header-brand-img" src={this.props.user.avatar ? this.props.user.avatar.contentUrl : '/assets/avatar.svg'} alt={this.props.user.name} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
